import { setContext, localRouteKey } from '@/utils/localStorage'
import structuredData from '@/plugins/structured-data'
import siteConfig from '@/utils/siteConfig'
import store from '@/plugins/vuex'

let authGuard = async (to, from, next) => {
  let usr = await getUser()
  structuredData.removeStructuredData('breadcrumb-list')
  if (usr !== null) {
    next()
  } else {
    if (to.path !== '/login') {
      setContext(localRouteKey, to.path)
    }
    if (to.path.indexOf('settings') > -1) {
      next('/')
    } else {
      next('/login')
    }
  }
}

let adminGuard = async (to, from, next) => {
  let usr = await getUser()
  if (usr !== null && JSON.parse(usr)["role"] === 99) {
    next()
  } else {
    if (to.path !== '/login') {
      setContext(localRouteKey, to.path)
    }
    if (to.path.indexOf('settings') > -1) {
      next('/')
    } else {
      next('/login')
    }
  }
}

let narrativeUserGuard = async (to, from, next) => {
  let usr = await getUser()
  if (usr !== null && JSON.parse(usr)["companyId"] === 1) {
    next()
  } else {
    if (to.path !== '/login') {
      setContext(localRouteKey, to.path)
    }
    if (to.path.indexOf('settings') > -1) {
      next('/')
    } else {
      next('/login')
    }
  }
}

let publicPageGuard = async (to, from, next) => {
  let usr = await getUser()
  siteConfig.initCallback(() => {
    if (store.getters.siteConfig.allPagesRequireAuth) {
      if (usr) {
        next()
      } else {
        if (to.path.indexOf('login') === -1 && to.path.indexOf('register') === -1 && to.path !== '/request-reset-password') {
					setContext(localRouteKey, to.path)
          next('/login')
        } else {
          next()
        }
      }
    } else {
      structuredData.removeStructuredData('breadcrumb-list')
			structuredData.insertStructuredData('breadcrumb-list', to.fullPath)
      next()
    }		
  })
}

let canRegisterGuard = (to, from, next) => {
  siteConfig.initCallback(() => {
    if (store.getters.siteConfig.preventRegistration) {
      next('/login')
    } else {
      next()
    }
  })
}

let isNarrativeDomainGuard = (to, from, next) => {
	if (siteConfig.narrativeHostnames.includes(window.location.hostname)) {
		next()
	} else {
		next('/')
	}
}

let getUser = async () => {
  if (store.state.user.authInfo) {
    return Promise.resolve(store.state.user.authInfo)
  }

  try {
    const authInfo = await store.dispatch('initAuthInfo', {openApiBaseUrl: store.getters.getOAUrl})
    return Promise.resolve(authInfo ? authInfo : null)
  } catch (e) {
    return Promise.resolve(null)
  }
}

export {
	authGuard,
	adminGuard,
	narrativeUserGuard,
	publicPageGuard,
	canRegisterGuard,
	isNarrativeDomainGuard
}