import Vue from "vue";
import VueMeta from "vue-meta";

Vue.use(VueMeta);

function makeCanonicalUrl() {
  return `https://app.narrative.io${window.location.pathname}`
}

export function loadMetadata(page, isNarrative) {
  const metadata = {
    title: page.title,
    meta: [
      { charset: "utf-8" },
      { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
      { name: "viewport", content: "width=device-width,initial-scale=1.0" }
    ],
    link: []
  };

  metadata.link.push({ rel: 'canonical', href: makeCanonicalUrl() })

  if (isNarrative) { // remove twitter metadata until supported in shop builder
    metadata.meta.push({ name: "twitter:card", content: "summary_large_image" })
    metadata.meta.push({ name: "twitter:site", content: "@narrative_io" })
    metadata.meta.push({ name: "twitter:image", content:"https://cdn.narrative.io/images/data-stream/images/meta-card-image.png"})
  }
  if (page.title) {
    metadata.meta.push({
      name: "title",
      content: page.title,
      property: "og:title",
    });
    if (isNarrative) {
      metadata.meta.push({ name: "twitter:title", content: page.title });
    }
  }
  if (page.url) {
    metadata.meta.push({ name: "url", content: page.url, property: "og:url" });
  }
  if (page.description) {
    metadata.meta.push({
      name: "description",
      content: page.description,
      property: "og:description",
    });
    metadata.meta.push({
      name: "twitter:description",
      content: page.description,
    });
  }
  if (page.invalidPage) {
    metadata.meta.push({
      name: 'prerender-status-code',
      content: '404'
    })
  }
  if (page.image) {
    metadata.meta.push({
      name: "image",
      content: page.image,
      property: "og:image",
    });
    metadata.meta.push({ name: "twitter:image", content: page.image });
  } else if (isNarrative) {
    metadata.meta.push({
      name: "image",
      content:
        "https://cdn.narrative.io/images/data-stream/images/meta-card-image.png",
      property: "og:image",
    });
    metadata.meta.push({
      name: "twitter:image",
      content:
        "https://cdn.narrative.io/images/data-stream/images/meta-card-image.png",
    });
  }
  return metadata;
}
