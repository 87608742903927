import sl from '@/utils/serviceLayer'
import Mutations from './UserMutations'
import { setContext, deleteContext, readContext, selectedCompanyIdKey } from '@/utils/localStorage'
import axios from 'axios'

function initialState() {
  return {
    authInfo: null,
    contextualCompanyId: null
  }
}

export async function fetchAuthInfo(openApiBaseUrl) {
  let whoamiResp
  try {
    whoamiResp = await axios.get(`${openApiBaseUrl}/whoami`)
  } catch (e) {
    return Promise.reject(e)
  }

  if (!whoamiResp?.data?.user_id) return Promise.reject("No user_id in whoami response")

  return Promise.resolve({
    id: whoamiResp.data.user_id,
    name: whoamiResp.data.name,
    email: whoamiResp.data.email,
    status: 0,
    role: whoamiResp.data.role === 'global_admin' ? 99 : 0,
    companyId: whoamiResp.data.current_company_scope.company_id,
    companyName: whoamiResp.data.current_company_scope.name,
    companyMembershipTier: 'basic',
    accountFeatures: ['SimpleBuyOrders'],
  })
}

let state = initialState;

let getters = {
  authenticated: state => state.authInfo != null && state.authInfo.accountFeatures != null,
  user: state => state.authInfo,
  userFirstName: state => state.authInfo !== null ? state.authInfo.name.split(" ")[0] : 'Guest',
  contextualCompanyId: state => {
    if (state.contextualCompanyId)
      return state.contextualCompanyId
    else if (state.authInfo)
      return state.authInfo.companyId
    else
      return null
  },
  accountFeatures: state => state.authInfo.accountFeatures || []
}

let mutations = {
  [Mutations.SELECT_COMPANY](state, companyId) {
    if (companyId) {
      setContext(selectedCompanyIdKey, companyId)
      state.contextualCompanyId = companyId
    }
    else {
      deleteContext(selectedCompanyIdKey)
      state.contextualCompanyId = null
    }
  },
  [Mutations.SET_AUTHENTICATED](state, authData) {
    if (authData) {
      state.authInfo = authData
      if (authData.role === 99) {
        const contextualCompanyId = readContext(selectedCompanyIdKey)
        state.contextualCompanyId = contextualCompanyId ? contextualCompanyId : authData.companyId
      } else {
        deleteContext(selectedCompanyIdKey)
        state.contextualCompanyId = null
      }
    }
    else {
      state.authInfo = null
    }
  },
  [Mutations.UPDATE_USER_DATA](state, usrData) {
    const keys = Object.keys(usrData)
    keys.forEach(key => {
      if (state.authInfo[key] !== undefined && state.authInfo[key] !== usrData[key]) {
        state.authInfo[key] = usrData[key]
      }
    })
  },
  [Mutations.LOGOUT](state) {
    authInfoPromise = null
    state.authInfo = null
    state.contextualCompanyId = null
  }
}

let authInfoPromise = null

let actions = {
  async initAuthInfo({ commit, state }, params) {
    if (state.authInfo) {
      if (params.callback) {
        params.callback(state.authInfo)
      }
      return
    }
    let authInfo

    if (!authInfoPromise) {
      try {
        authInfoPromise = fetchAuthInfo(params.openApiBaseUrl)
      } catch (e) {
        authInfoPromise = null
      }
    }  

    try {
      authInfo = await authInfoPromise
    } catch (e) {}
    if (authInfo) {
      commit(Mutations.SET_AUTHENTICATED, authInfo)
    }
    if (params.callback) {
      params.callback(authInfo)
    }
    if (params.callback) {
      params.callback(authInfo)
    }
  },
  async clear({ commit }) {
    try {
      await sl.post(`/api/open-api/logout`)
      commit(Mutations.LOGOUT)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject()
    }  
  },
  requestUserData(store) {
    sl.get('/api/user')
      .then(resp => {
        store.commit(Mutations.UPDATE_USER_DATA, resp.data)
      })
  },
  updateUserData(store, params) {
    store.commit(Mutations.UPDATE_USER_DATA, params)
  },
  [Mutations.ACTION_SELECT_COMPANY]({ commit }, companyId) {
    return commit(Mutations.SELECT_COMPANY, companyId)
  },
  [Mutations.ACTION_SET_AUTHENTICATED]({ commit }, authData) {
    return commit(Mutations.SET_AUTHENTICATED, authData)
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}