function setGlobalColors(colorsConfig, styles) {
  Object.keys(colorsConfig).forEach(key => {
    styles.setProperty(`--nio-theme-${toKebabCase(key)}`, colorsConfig[key]);
  })
}

function setTheme(config) {
  let styles = document.documentElement.style;
  if (config.global) {
    if (config.global.colors) {
      setGlobalColors(config.global.colors, styles)
    }
  }
}

function getCssVar(name) {
  let styles = document.documentElement.style
  return styles.getPropertyValue(name)
}

let toKebabCase = (string) => {
  return string && string
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-')
}

let capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export {
  setTheme,
  getCssVar,
  toKebabCase,
  capitalize
}