import Vue from 'vue'
import VueGtag from 'vue-gtag'

function setGTMConfig(config, router) {
  Vue.use(VueGtag, {
    config: {
      id: config.gtmId,
      enabled: true,
    }
  }, router)
}

export {
  setGTMConfig,
}