import sl from '@/utils/serviceLayer'

let _config = null
let _hostname = null
let _initCallbackFns = []
let _initialized = false

const NarrativeHostnames = [
  'app.narrative.io',
  'app-dev.narrative.io',
  'localhost'
]

const isNarrativeDomain = NarrativeHostnames.includes(window.location.hostname)

const NarrativeConfig = {
  isNarrative: true,
  companyId: 1,
  config: {
    featuredSearchTerms: ['Breaking News', 'Food Delivery', 'Fanatics', 'Casual Games']
  },
  emailTemplate: {
    companyName: 'Narrative',
    logo: 'https://cdn.narrative.io/cdn-cgi/image/w=200,h=38,q=75,f=auto/images/company-logos/prod/nio-narrative.png'
  },
  global: {
    logo: "https://cdn.narrative.io/images/data-stream/images/logo-narrative.png",
    logoAlt: "Narrative I/O"
  },
  navbar: {
    backgroundColor: "#010A28",
    linkColor: "#BDCAF9",
    linkText: {
      home: "Data Streams",
      providers: "Providers",
      apps: "Apps"
    }
  },
  pages: {
    home: {
      hero: {
        headline: {
          text: "The Data Collaboration Platform that makes it easy to buy, sell, and win.",
          color: "FFFFFF"
        },
        tagline: {
          text: "Narrative removes the friction from buying and selling data so you can focus on what you do best.",
          color: '#AEB9E8'
        },
        backgroundColor: "#010A28",
        backgroundImage: "https://cdn.narrative.io/cdn-cgi/image/w=2048,h=637,q=100,f=auto/images/data-stream/images/landing-bg-header.png"
      }
    },
    loginRegister: {
      backgroundColor: '#0922A6',
    }
  }
}

let fetchSiteConfig = (domain) => {
  return sl.post('/api/manifest', { 'hostName': domain})
    .then(resp => {
      return resp.data
    })
}

const siteConfig = {
  narrativeHostnames: NarrativeHostnames,
  initCallback(callbackFn) {
    _initCallbackFns.push(callbackFn)
    if (_initialized) {
      _initCallbackFns.forEach(fn => fn.call())
      _initCallbackFns = []
    }
  },	
  initialized() {
    _initialized = true
    _initCallbackFns.forEach(fn => fn.call())
    _initCallbackFns = []
  },
  getSiteConfig() {
    _hostname = window.location.hostname
    
    // Override the hostname via query parameter hostname
    // This is helpful when the DNS is not yet setup and we
    // want to test a datashop:
    // https://data-shops.narrative.io?hostname=awisdata.com
    const params = new URLSearchParams(window.location.search)
    if (params.has("hostname")) {
      _hostname = params.get("hostname")
    }

    return new Promise((resolve, reject) => {
      if (_config) {
        resolve(_config)
      } else {
        if (!NarrativeHostnames.includes(_hostname)) {
          fetchSiteConfig(_hostname).then(config => {
            if (config !== undefined) {
              _config = config
              resolve(config)
            } else {
              // If hostname is setup, but not configured with manifest.
              _config = NarrativeConfig
              resolve(NarrativeConfig)
            }
          })
        } else {
          _config = NarrativeConfig
          resolve(NarrativeConfig)
        }
      }
    })
  },
  getHostname() {
    return _hostname
  }
}

Object.freeze(siteConfig)
export { isNarrativeDomain }
export default siteConfig