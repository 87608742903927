export const localStoreageCartKey = "nio:cart"
export const selectedCompanyIdKey = "nio:context"
export const localRouteKey = "nio:marketplace-route"

export function readContext(key) {
  try {
    return localStorage.getItem(key)
  } catch (e) { return null }
}

export function readContextAsJson(key) {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (e) { return null }
}

export function setContext(key, data) {
  localStorage.setItem(key, data)
}

export function deleteContext(key) {
  localStorage.removeItem(key)
}

export function setCustomAppParameters(appName, params) {
  const key = 'nio:app-custom-parameters'
  setContext(key, JSON.stringify({
    appName,
    params
  }))
}

export function clearCustomAppParameters() {
  deleteContext('nio:app-custom-parameters')
}
