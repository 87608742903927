import Vue from 'vue'
import App from './App.vue'
import vuex from './plugins/vuex'
import { router } from './plugins'
import vuetify from './plugins/vuetify'
import CartMutations from '@/stores/cart/CartMutations'
import Tacklebox from '@narrative.io/tackle-box'
import '@narrative.io/tackle-box/dist/tackle-box.css'
import siteConfig from './utils/siteConfig'
import { setTheme } from './utils/theme'
import { setGTMConfig } from './utils/gtmConfig'
import VSanitize from "v-sanitize"
// import VueCompositionAPI from '@vue/composition-api'

siteConfig.getSiteConfig().then(config => {
  if (config) {
    setTheme(config)
    if (config.gtmId !== undefined && config.gtmId !== null) {
      setGTMConfig(config, router)
    }
  }
  Vue.config.productionTip = false
  Vue.config.devtools = true
  Vue.use(Tacklebox)
  Vue.use(VSanitize)
  //  Vue.use(VueCompositionApi)

  new Vue({
    router,
    store: vuex,
    vuetify,
    render: h => h(App),
    created() {
      this.$store.dispatch(CartMutations.INITIALIZE_CART)
    }
  }).$mount('#app')
})



