import sl from '@/utils/serviceLayer'
import axios from 'axios'

let state = {
  token: null,
  rToken: null,
  tkExpiry: null,
  rtkExpiry: null
}

let getters = {
  getToken: (state) => {
    let curDate = new Date()
    return curDate.getTime() < state.tkExpiry ? state.token : null
  },
  getOARefreshToken: (state) => {
    let curDate = new Date()
    return curDate.getTime() < state.rtkExpiry ? state.rToken : null
  },
  getOAUrl: () => {
    // DSM should use the open api proxy so that cookie authentication works
    return "/openapi"
  },
  getOAUrlApps: () => {
    // OAuth apps should use the actual URL
    if (process.env.VUE_APP_STAGE == 'localhost') {
      return 'http://localhost:8081/external'
    }
    if (process.env.VUE_APP_STAGE == 'production') {
      return 'https://api.narrative.io'
    }
    return 'https://api-dev.narrative.io'
  }
}

let mutations = {
  ['SET_TOKEN'](state, token) {
    state.token = token
  },
  ['SET_REFRESH_TOKEN'](state, token) {
    state.rToken = token
  },
  ['SET_TOKEN_EXPIRY'](state, expiry) {
    state.tkExpiry = expiry
  },
  ['SET_TOKEN_REFRESH_EXPIRY'](state, expiry) {
    state.rtkExpiry = expiry
  }
}

let actions = {
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },
  setRefreshToken({ commit }, token) {
    commit('SET_REFRESH_TOKEN', token)
  },
  setTokenExpiry({ commit }, expiry) {
    let exp = new Date().getTime() + (expiry * 1000)
    commit('SET_TOKEN_EXPIRY', exp)
  },
  setRefreshTokenExpiry({ commit }, expiry) {
    let exp = new Date().getTime() + (expiry * 1000)
    commit('SET_TOKEN_REFRESH_EXPIRY', exp)
  },
  requestToken(store) {
    return new Promise((resolve, reject) => {
      if (store.getters.getOARefreshToken !== null) {
        if (store.getters.getToken !== null) {
          resolve(store.getters.getToken)
        } else {
          // Refresh token flow
          axios.post(`${store.getters.getOAUrl}/oauth/token`).then(resp => {
            let data = resp.data
            if (data?.access_token) {
              store.dispatch('setToken', data.access_token)
              store.dispatch('setTokenExpiry', data.access_token_expires_in)
              store.dispatch('setRefreshToken', data.refresh_token)
              store.dispatch('setRefreshTokenExpiry', data.refresh_token_expires_in)
            }
            resolve(state.token)
          })
        }
      } else {
        sl.post('/api/open-api/dsm/token')
          .then(resp => {
            let data = resp.data
            if (data?.access_token) {
              store.dispatch('setToken', data.access_token)
              store.dispatch('setTokenExpiry', data.access_token_expires_in)
              store.dispatch('setRefreshToken', data.refresh_token)
              store.dispatch('setRefreshTokenExpiry', data.refresh_token_expires_in)
            }
            resolve(state.token)
          })
      }
    })
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}