import siteConfig from '@/utils/siteConfig'

let state = {
  wHostname: null,
  siteConfig: null
}

let getters = {
  hostname: (state) => {
    return state.wHostname
  },
  siteConfig: (state) => {
    return state.siteConfig
  }
}

let mutations = {
  ['SET_HOSTNAME'] (state, hostname) {
    state.wHostname = hostname
  },
  ['SET_SITE_CONFIG'] (state, config) {
    state.siteConfig = config
  }
}

let actions =  {
  setHostname({ commit }, hostname) {
    commit('SET_HOSTNAME', hostname)
  },
  setSiteConfig({ commit }, config) {
    commit('SET_SITE_CONFIG', config)
    if (config) {
      siteConfig.initialized()
    }
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}