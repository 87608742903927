
import CartMutations from '@/stores/cart/CartMutations'
import sum from 'lodash/sum'
import { setContext, readContextAsJson, localStoreageCartKey } from '@/utils/localStorage'
import sl from '@/utils/serviceLayer'
function loadCartFromLocalStorage() {
  let c = readContextAsJson(localStoreageCartKey)
  if (c && Array.isArray(c))
    return c.filter(p => !!p.id)
  else return []
}

function saveCart(c) {
  setContext(localStoreageCartKey, JSON.stringify(c))
}
export default {
  state: {
    cart: loadCartFromLocalStorage() || [],
    idCount: 0
  },
  mutations: {
    [CartMutations.INITIALIZE_CART] (state) {
      state.cart = loadCartFromLocalStorage()
    },
    [CartMutations.UPDATE_ID_COUNT] (state, count) {
      state.idCount = count
      saveCart(state.cart)
    },
    [CartMutations.ADD_CART_PRODUCT] (state, product) {
      state.cart.push(product)
    },
    [CartMutations.REMOVE_CART_PRODUCT] (state, productId) {
      state.cart = state.cart.filter(p => p.id != productId)
    },
    [CartMutations.EMPTY_CART] (state) {
      state.cart = []
      state.idCount = 0
      saveCart(state.cart)
    }
  },
  actions: {
    [CartMutations.INITIALIZE_CART] ({ commit, dispatch, state }) {
      commit(CartMutations.INITIALIZE_CART)
      return dispatch(CartMutations.UPDATE_ID_COUNT)
    },
    [CartMutations.ADD_CART_PRODUCT] ({ commit, dispatch, state }, product) {
      commit(CartMutations.ADD_CART_PRODUCT, product)
      return dispatch(CartMutations.UPDATE_ID_COUNT)
    },
    [CartMutations.REMOVE_CART_PRODUCT] ({ commit, dispatch, state }, productId) {
      commit(CartMutations.REMOVE_CART_PRODUCT, productId)
      return dispatch(CartMutations.UPDATE_ID_COUNT)
    },
    [CartMutations.UPDATE_ID_COUNT] ( { commit, state }) {
      if (state.cart.length > 1) {
        return sl.post('/api/products/combinedCardinality', {ids: state.cart.map(p => p.id)})
          .then(resp => {
            commit(CartMutations.UPDATE_ID_COUNT, resp.data)
          })
      }
      else {
        commit(CartMutations.UPDATE_ID_COUNT, sum(state.cart.map(p => p.idCount)))
      }
    }
  },
  getters: {
    cart: state => state.cart,
    containsProduct: (state) => (id) => state.cart.find(p => p.id == id) != null,
    idCount: state => state.idCount
  }
}