import UserMutations from '@/stores/user/UserMutations';

export default {
  computed: {
    $_userMixin_currentUser() {
      let usr = this.$store.getters.user
      if (usr) {
        usr.contextCompany = parseInt(this.$store.getters.contextualCompanyId)
      }
      return usr
    },
    $_userMixin_authenticated() { return this.$store.getters.authenticated },
    $_userMixin_contextualCompanyId() { return this.$store.getters.contextualCompanyId },
    $_userMixin_userIsAdmin() { return this.$store.getters.user && this.$store.getters.user.role === 99 },
    $_userMixin_isDataShopsBetaCompany() { return false } // preserving this functionality for future use
  },
  methods: {
    $_userMixin_selectCompanyContext(companyId) { return this.$store.commit(UserMutations.SELECT_COMPANY, companyId) },
    async $_userMixin_logout() { 
      await this.$store.dispatch('clear') 
      this.$router.go()
    }
  }
}
