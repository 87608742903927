<template lang="pug">
  notifications(
    group="main" 
    position="top center" 
    width="440px"
  )
    template(
      slot="body" 
      slot-scope="props"
    )
      .notification-container
        .content
          .title {{ props.item.title }}
          .text {{ props.item.text }}
        a.close(@click="props.close").pl-2
          x-icon
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
  .notification-container
    display: flex
    align-items: center
    background-color: $c-primary-lightest
    padding: 1rem
    border-left: 2px solid $c-primary
    .content
      display: flex
      flex-direction: column
      flex: auto
    ::v-deep .title
      color: $c-primary
      font-size: 8px
      text-transform: uppercase
    .text
      color: $c-primary-darkest 
      font-size: 14px
    .close
      color: $c-primary-dark
</style>