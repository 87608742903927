import moment from 'moment'
import siteConfig from '@/utils/siteConfig'
import DataStreamMixin from '@/shared-components/DataStreamMixin'

let globals = {
  siteConfig: null,
  appBase: null,
  orgName: null,
  logo: null
}

function makeLogoSchema() {
  return {
    "@type": "ImageObject",
    "@id": `${globals.appBase}#logo`,
    "url": globals.siteConfig.global.logo,
    "name": "Logo"
  }
}

function makeSiteSchema() {
  return {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "@id": `${globals.appBase}#site`,
    "url": globals.appBase,
    "name": globals.siteName,
    "publisher": {
      "@type": "Organization",
      "@id": `${globals.appBase}#org`,
      "name": globals.orgName
    },
    "image": 	{
      "@type": "ImageObject",
      "@id": `${globals.appBase}#logo`,
      "name": "Logo"
    },
    "potentialAction": {
      "@type": "SearchAction",
      "target": `${globals.appBase}/products?q={q}`,
      "query-input": "name=q"
    }	
  }
}

function makeOrgSchema() {
  const orgSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": `${globals.appBase}#org`,
    "url": globals.appBase,
    "name": globals.orgName,
    "logo": {
      "@type": "ImageObject",
      "@id": `${globals.appBase}#logo`,
      "name": "Logo"
    }
  }
  if (globals.siteConfig.isNarrative) {
    orgSchema.contactPoint =  {
      "@type": "ContactPoint",
      "email": "info@narrative.io",
      "contactType": "General Information"
    }
  }
  return orgSchema
}

function addDaysToToday(daysToAdd) {
  let date = moment(new Date()) 
  let newDate = date.add(daysToAdd, 'd').format('YYYY-MM-DD')
  return newDate
}

function capitalize(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function createBreadcrumb(position, name, fragment) {
  return {
    "@type": "ListItem",
    "position": position,
    "item": {
      "@id": fragment,
      "name": name
    }
  }
}

// function getSupplierSchema(supplier) {
//   const supplierSchema = {
//     "@context": "http://schema.org",
//     "@type": "Organization",
//     "@id": `${globals.appBase}/suppliers/${supplier.name}#provider`,
//     "url": `${globals.appBase}/products/${supplier.name}`,
//     "name": supplier.name,
//     "url": supplier.website
//   }

//   if (supplier.email && supplier.email !== null) {
//     supplierSchema["email"] = supplier.contact
//   }

//   if (supplier.location && supplier.location !== null) {
//     supplierSchema["location"] = supplier.location
//   }

//   if (supplier.url && supplier.url !== null) {
//     supplierSchema["url"] = supplier.website
//   }

//   if (supplier.shortDesciption && supplier.shortDesciption !== null) {
//     supplierSchema["description"] = supplier.shortDesciption
//   }

//   if (supplier.companySize && supplier.companySize !== null) {
//     supplierSchema["numberOfEmployees"] =  {
//       "@type": "QuantitativeValue",
//       "text": supplier.companySize
//     }
//   }

//   return supplierSchema	
// }

function makeProductName(product) {
  return `Data Stream: ${product.name}`
}

function makeProductDescription(product) {
  return product.content?.description
}

function getProductSchema(product) {
  let offer
  if (globals.siteConfig.isNarrative) {
    offer = product.offers.find(offer => offer.channel === 'data_streams_market')
  } else {
    offer = product.offers.find(offer => offer.channel === 'data_shop')
  }
  const productSchema = {
    "@type": "Product",
    "@id": `${globals.appBase}/products/${product.company_id}/${product.slug}#product`,
    "url": `${globals.appBase}/products/${product.company_id}/${product.slug}`,
    "name": makeProductName(product),
    "brand": {
      "@type":"Organization",
      "@id": `${globals.appBase}#org`,
      "name": globals.orgName
    },
    "sku": product.id,
    "offers": {
      "@type": "Offer",
      "url": `${globals.appBase}/products/${product.company_id}/${product.slug}`,
      "price": parseFloat(DataStreamMixin.methods.$_dataStreamMixin_getDataStreamPrice(product)).toFixed(2),
      "priceCurrency": globals.siteConfig.isNarrative ? product.currency : "USD",
      "availability":"https://schema.org/InStock",
      "priceValidUntil": addDaysToToday(30),
      "itemOffered": {
        "@type": "Dataset",
        "@id": `${globals.appBase}/products/${product.company_id}/${product.slug}#dataset`,
        "name": product.name
      }
    }			
  }
  if (product.content?.icon) {
    productSchema.image = [product.content.icon]
  } else {
    productSchema.image = "https://cdn.narrative.io/data-studio/images/narrative-placeholder-primary.svg"
  }
  if (makeProductDescription(product)) {
    productSchema.description = makeProductDescription(product)		
  }
  return productSchema
}

function getDatasetSchema(product) {  
  const datasetSchema = {
    "@type": "Dataset",
    "@id": `${globals.appBase}/products/${product.company_id}/${product.slug}#dataset`,
    "url": `${globals.appBase}/products/${product.company_id}/${product.slug}`,
    "name": makeProductName(product),
    "license":"https://app.narrative.io/data-license/",
    "publisher":  {
      "@type":"Organization",
      "@id": `${globals.appBase}#org`,
      "name": globals.orgName
    },	
    "provider":  {
      "@type":"Organization",
      "@id": `${globals.appBase}#org`,
      "name": globals.orgName
    }
  }	

  const variableMeasuredSchema = []

  if (product.data_rules && product.data_rules.column_sets && product.data_rules.column_sets.length > 0) {
    const column_set = product.data_rules.column_sets[0]
    if (column_set.fields && column_set.fields.length > 0) {
      column_set.fields.map(field => {
        if (field.exported) {
          variableMeasuredSchema.push({
            "@type": "PropertyValue",
            "name": field.field
          })
        }
      })
    }
  }

  if (variableMeasuredSchema.length > 0) {
    datasetSchema.variableMeasured = variableMeasuredSchema
  }

  if (makeProductDescription(product)) {
    datasetSchema.description = makeProductDescription(product)
  }

  if (!datasetSchema.description || datasetSchema.description.length < 50) {
    datasetSchema.description = `${product.name}: ${datasetSchema.description ? datasetSchema.description : ''}` 
    datasetSchema.description = datasetSchema.description.slice(0, datasetSchema.description.lastIndexOf("."))
    datasetSchema.description += `. Dataset includes data: ${datasetSchema.variableMeasured.map(field => `${field.name}, `).join('')}`
    datasetSchema.description = datasetSchema.description.slice(0, datasetSchema.description.lastIndexOf(",")) + '.'
  }

  if (datasetSchema.description.length < 50) {
    datasetSchema.description = `${datasetSchema.description}`
    datasetSchema.description = datasetSchema.description.slice(0, datasetSchema.description.lastIndexOf("."))
    datasetSchema.description += '. Powered by Narrative I/O, the Data Collaboration Platform that helps you buy, sell, and win.'
  }

  if (datasetSchema.description.length > 5000) {
    datasetSchema.description = datasetSchema.description.substr(0, 4999)
  }

  if (product.content?.icon) {
    datasetSchema.thumbnailUrl = product.content.icon
  } else {
    datasetSchema.thumbnailUrl = "https://cdn.narrative.io/data-studio/images/narrative-placeholder-primary.svg"
  }

  // datasetSchema.creator = {
  //   "@type": "Organization",
  //   "name": product.creator,
  //   "image": [product.imageUrl],
  // }
  // keywords = [...keywords, product.creator, product.productType]

  if (product.tags && product.tags.length > 0) {
    datasetSchema.keywords = [...product.tags]
  }
  
  datasetSchema.creator = {
    "@type":"Organization",
    "@id": `${globals.appBase}#org`,
    "name": globals.orgName
  }

  return datasetSchema
}

export default {
  insertStructuredData(type, payload) {
    return new Promise((resolve, reject) => {
      siteConfig.getSiteConfig().then(config => {
        globals.siteConfig = config
        globals.appBase = config.isNarrative ? "https://app.narrative.io" : window.location.origin
        globals.siteName = config.isNarrative ? 'Narrative' : window.location.hostname // TODO: We should add Org Name & Org Url to Shop Builder to better support this
        globals.orgName = config.isNarrative ? 'Narrative I/O, Inc.' : globals.siteConfig.config && globals.siteConfig.config.shopName ? globals.siteConfig.config.shopName : window.location.hostname

        const className = `structured-data-${type}`
        if (!document.head.getElementsByClassName(className).length) {
          const script = document.createElement("script");
          let schema
          switch (type) {
            case "org":
              schema = makeOrgSchema()
              break;
            case "site":
              schema = makeSiteSchema()
              break;
            case "logo":
              schema = makeLogoSchema()
              break;
            case "breadcrumb-list":
              const fragments = payload.split('/')

              schema = {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": []
              }		

              schema.itemListElement.push(createBreadcrumb(1, "Home", `${globals.appBase}/`))
              if (fragments[1] === "login") {
                schema.itemListElement.push(createBreadcrumb(2, "Login", `${globals.appBase}/login/`))
              } else if (fragments[1] === "register") {
                schema.itemListElement.push(createBreadcrumb(2, "Register", `${globals.appBase}/login/`))
              } else if (fragments[1] === "products") {
                schema.itemListElement.push(createBreadcrumb(2, "Products", `${globals.appBase}/products/`))
                if (fragments[2] && fragments[3]) {
                  schema.itemListElement.push(createBreadcrumb(3, `Product Details: ${fragments[3]}`, `${globals.appBase}/products/${fragments[2]}/${fragments[3]}/`))
                } else if (fragments[2]) {
                  schema.itemListElement.push(createBreadcrumb(3, `Product Details: ${fragments[2]}`, `${globals.appBase}/products/1/${fragments[2]}/`))
                }
              } 
              // else if (fragments[1] === "suppliers") {
              //   schema.itemListElement.push(createBreadcrumb(2, "Data Providers", `${globals.appBase}/suppliers/`))
              //   if (fragments[2]) {
              //     schema.itemListElement.push(createBreadcrumb(3, `Data Provider: ${fragments[2]}`, `${globals.appBase}/suppliers/${fragments[2]}/`))
              //   }
              // }
              else if (fragments[1] === "cart") {
                schema.itemListElement.push(createBreadcrumb(2, "Shopping Cart", `${globals.appBase}/cart/`))
              }					
              break;	
            case "product-detail":
              schema = {
                "@context": "https://schema.org",
                "@graph": [
                  getProductSchema(payload),
                  getDatasetSchema(payload)
                ]	
              }
              break;			
            case "attribute":
              schema = {
                "@context": "https://schema.org",
                "@type": "Property",
                "@id": `${globals.appBase}/attributes/${payload.slug}#attribute`,
                "url": `${globals.appBase}/attributes/${payload.slug}`,
                "name": payload.name,
                "description": payload.description
                }
              break;			
            case "search-results":
              schema = {
                "@context": "http://schema.org",
                "@type": "SearchResultsPage",
                "mainEntity": [{
                  "@type": "ItemList",
                  "name": `Data Product results for ${payload.query}`,
                  "itemListOrder": "http://schema.org/ItemListOrderAscending",
                  "itemListElement": payload.products.filter(product => product.id !== -1).slice(0, 10).map((product, index) => {
                    return {
                      "@type": "ListItem",
                      "position": index + 1,
                      "url": `${globals.appBase}/products/${product.company_id}/${product.slug}`
                    }
                  })	
                }]
              }
              break;	
            // case "suppliers-search-results":
            //   schema = {
            //     "@context": "http://schema.org",
            //     "@type": "SearchResultsPage",
            //     "mainEntity": [{
            //       "@type": "ItemList",
            //       "name": `Data Product Suppliers results for ${payload.query}`,
            //       "itemListOrder": "http://schema.org/ItemListOrderAscending",
            //       "itemListElement": payload.suppliers.filter(supplier => supplier.id !== -1).map((supplier, index) => {
            //         return {
            //           "@type": "Organization",
            //           "position": index + 1,
            //           "url": `${globals.appBase}/suppliers/${encodeURI(supplier.name)}`
            //         }
            //       })	
            //     }]
            //   }
            //   break;
            // case "supplier-detail":
            //   schema = getSupplierSchema(payload)
            //   break;				
            default:
              break;
          }

          if (schema) {
            script.setAttribute("class", className);
            script.type = "application/ld+json"
            script.text = JSON.stringify(schema);
            document.head.appendChild(script);
          }
        }
      })
    })
  },
  removeStructuredData(type) {
    const className = `structured-data-${type}`
    const element = document.head.getElementsByClassName(className)[0]
    if (element) {
      document.head.removeChild(element)
    }	
  }
}