import axios from 'axios'
import siteConfig from '@/utils/siteConfig'
import UserMixin from "@/shared-components/UserMixin"
import { mapActions, mapGetters } from 'vuex'

const isDSM = siteConfig.narrativeHostnames.includes(window.location.hostname)

export default {
  mixins: [
    UserMixin
  ],
  methods: {
    ...mapActions(['requestToken']),
    $_dataStreamMixin_getByCompany(companyId) {
      return this.getRequestParameters().then(({ baseUri, requestHeaders }) =>
        axios.get(`${baseUri}/company/${companyId}`, requestHeaders)
      )
    },
    $_dataStreamMixin_getBySlug(companyId, slug) {
      return this.getRequestParameters().then(({ baseUri, requestHeaders }) =>
        axios.get(`${baseUri}/company/${companyId}/slug/${slug}`, requestHeaders)
      )
    },
    $_dataStreamMixin_getDataStreams(query) {
      return this.getRequestParameters().then(({ baseUri, requestHeaders }) => {
        return new Promise((resolve, reject) => {
          let promise
          if (isDSM) {
            if (query.length > 0) {
              promise = axios.get(`${baseUri}/sales-channel/data-streams-market/search?q=${encodeURIComponent(query)}`, requestHeaders)
            } else {
              promise = axios.get(`${baseUri}/sales-channel/data-streams-market/search?q=${encodeURIComponent('-app AND (ecobee OR data OR advertising)')}`, requestHeaders)

            }
          } else {
            if (query.length > 0) {
              promise = axios.get(`${baseUri}/company/${this.siteConfig.companyId}/host/${siteConfig.getHostname()}/search?q=${encodeURIComponent(query)}`, requestHeaders)
            } else {
              promise = axios.get(`${baseUri}/company/${this.siteConfig.companyId}/host/${siteConfig.getHostname()}`, requestHeaders)
            }
          }
          promise.then(resp => resolve(resp), () => reject())
        })
      })
    },
    $_dataStreamMixin_getDataStreamPrice(dataStream) {
      if (isDSM) {
        return dataStream?.offers?.find(el => {
          return el.channel === 'data_streams_market'
        })?.pricing?.micro_cents_usd / 100000

      } else {
        return dataStream?.offers?.find(el => {
          return el.channel === 'data_shop'
        })?.pricing?.micro_cents_usd / 100000
      }
    },
    $_dataStreamMixin_getDataStreamIcon(dataStream) {
      if (dataStream.content?.icon) {
        return dataStream.content.icon
      } else {
        if (this.siteConfig.global?.placeholderImage) {
          return this.siteConfig.global.placeholderImage
        } else {
          return 'https://cdn.narrative.io/data-studio/images/narrative-placeholder-primary.svg'
        }
      }
    },
    getRequestParameters() {
      if (this.$_userMixin_authenticated) {
        return this.requestToken().then(token => new Promise((resolve) => resolve({
          baseUri: `${this.getOAUrl}/data-stream`,
          requestHeaders: {
            headers: {
              ...(token ? { 'Authorization': `Bearer ${token}` } : {})
            }
          }
        })))
      } else {
        return new Promise((resolve) => resolve({
          baseUri: `${this.getOAUrl}/public/data-stream`,
          requestHeaders: {}
        }))
      }
    },
  },
  computed: {
    ...mapGetters(['getOAUrl', 'siteConfig']),
  }
}