import multiguard from 'vue-router-multiguard'
import * as Guards from './guards'

const ProductSearchPage = () => import('@/pages/products/ProductSearchPage')
const ProductDetailsPage = () => import('@/pages/product-details/ProductDetailsPage')
const CartPage = () => import('@/pages/cart/CartPage')
const CheckoutPage = () => import('@/pages/checkout/CheckoutPage')
const HomePage = () => import('@/pages/home/HomeLanding')
const ProvidersPage = () => import('@/pages/suppliers/ProvidersPage')
const ProvidersDetailPage = () => import('@/pages/suppliers/ProvidersDetailPage')
const SubscriptionsPage = () => import('@/pages/subscriptions/SubscriptionsPage')
const LoginRegister = () => import('@/pages/login/LoginRegister')
const Settings = () => import('@/pages/settings/Settings')
const AppDetailPage = () => import ('@/pages/apps/app-detail/AppDetailPage')
const AppsPage = () => import ('@/pages/apps/AppsPage')
const AppRunnerPage = () => import('@/pages/apps/app-runner/AppRunnerPage')
const DataPurchaseAgreementPage = () => import('@/pages/legal/DataPurchaseAgreementPage')
const SiteDesignerPage = () => import('@/pages/site-designer/SiteDesignerPage')
const DataShopPreviewPage = () => import('@/pages/site-designer/DataShopPreviewPage')
const SupportPage = () => import('@/pages/support/SupportPage')
const AttributeListingPage = () => import('@/pages/attributes/attribute-listing/AttributeListingPage')
const AttributeDetailPage = () => import('@/pages/attributes/attribute-detail/AttributeDetailPage')

export default [
  { path: '/', name: 'home', component: HomePage, beforeEnter: Guards.publicPageGuard },
  { path: '/apps', name: 'apps', component: AppsPage, beforeEnter: Guards.publicPageGuard }, 
  { path: '/apps/:appSlug', name: 'app-runner', component: AppRunnerPage, props: true },
  { path: '/apps/:appSlug/:firstAccess', name: 'app-runner-first-access', component: AppRunnerPage, props: true },
  { path: '/app/:appSlug/', name: 'app', component: AppDetailPage, beforeEnter: Guards.publicPageGuard, props: true},
  { path: '/app/bundle/:appBundleSlug/', name: 'app', component: AppDetailPage, beforeEnter: Guards.publicPageGuard, props: true},
  { path: '/login', name:'login', component: LoginRegister, beforeEnter: Guards.publicPageGuard },
  { path: '/register', name:'register', component: LoginRegister, beforeEnter: Guards.publicPageGuard  },
  { path: '/legal/data-purchase-agreement', name:'data-purchase-agreement', component: DataPurchaseAgreementPage, beforeEnter: Guards.publicPageGuard },
  { path: '/products', component: ProductSearchPage, beforeEnter: Guards.publicPageGuard },
  { path: '/products/:slug', component: ProductDetailsPage, beforeEnter: Guards.publicPageGuard, props: true },
  { path: '/products/:companyId/:slug', component: ProductDetailsPage, beforeEnter: Guards.publicPageGuard, props: true },
  { path: '/cart', name: 'cart', component: CartPage, beforeEnter: Guards.publicPageGuard },
  { path: '/checkout', name: 'checkout', component: CheckoutPage, beforeEnter: Guards.authGuard },
  { path: '/providers', name:'providers', component: ProvidersPage, beforeEnter: multiguard([Guards.isNarrativeDomainGuard, Guards.publicPageGuard]) },
  { path: '/providers/:providerId', component: ProvidersDetailPage, beforeEnter: multiguard([Guards.isNarrativeDomainGuard, Guards.publicPageGuard]), props: true  },
  { path: '/subscriptions', component: SubscriptionsPage, beforeEnter: Guards.authGuard },
  { path: '/settings/:defaultMenu', component: Settings, beforeEnter: Guards.authGuard, props: (r) => ({defaultMenu: r.params.defaultMenu }) },
  { path: '/site-designer', name: 'siteDesigner', component: SiteDesignerPage, beforeEnter: Guards.narrativeUserGuard },
  { path: '/data-shop-preview', name: 'dataShopPreview', component: DataShopPreviewPage, beforeEnter: Guards.authGuard },
  { path: '/support', name: 'support', component: SupportPage, beforeEnter: Guards.authGuard },
  { path: '/attributes', name: 'attribute-listing', component: AttributeListingPage, beforeEnter: multiguard([Guards.isNarrativeDomainGuard, Guards.publicPageGuard]) },
  { path: '/attributes/:attributeSlug', name: 'attribute-detail', component: AttributeDetailPage, beforeEnter: multiguard([Guards.isNarrativeDomainGuard, Guards.publicPageGuard]), props: true },
]
