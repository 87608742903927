<template lang="pug">
  .footer(
    :style="{backgroundColor: backgroundColor}"
    :class="{'white-label': siteConfig && !siteConfig.isNarrative}"
  )
    .nio-container
      .left(v-if="siteConfig && siteConfig.isNarrative")
        a.body.text-primary-light(
          href="https://www.narrative.io" 
          target="_blank"
        ) Visit <strong class="text-white body">Narrative.io</strong>
      .center
        AppLogo
        .powered-by(
          v-if="siteConfig && !siteConfig.isNarrative"
          :style="{color: whitelabelTextColor}"
        ) Powered by 
          a.nio-bold(
            :style="{color: whitelabelTextColor}"
            href="https://narrative.io"
          ) Narrative I/O
        .copyright.body-small.text-primary-light(v-if="siteConfig && siteConfig.isNarrative") Copyright © {{ currentYear }} Narrative I/O, Inc. All Rights Reserved
        a.privacy-policy.body-small.text-white(
          v-if="siteConfig && siteConfig.isNarrative"
          href="https://www.narrative.io/privacy-policy" 
          target="_blank"
        ) Privacy policy
      .right(v-if="siteConfig && siteConfig.isNarrative")
        a.share-link(
          :href="generateShareLink('twitter')" 
          target="_blank"
        )
          NioIcon(
            name="utility-twitter" 
            size="16" 
            color="white"
          )
          .text.button-label-small.text-white Twitter
        a.share-link(
          :href="generateShareLink('linkedin')" 
          target="_blank"
        )
          NioIcon(
            name="utility-linkedin" 
            size="16" 
            color="white"
          )
          .text.button-label-small.text-white LinkedIn    
</template>

<script>  

import AppLogo from '@/components/AppLogo'
import { mapGetters } from 'vuex'
import ThemeMixin from '@/shared-components/ThemeMixin'

export default {
  components: { AppLogo },
  mixins: [ThemeMixin],
  computed: {
    currentYear() {
      let date = new Date()
      return date.getFullYear()
    },
    backgroundColor() {
      return this.siteConfig && this.siteConfig.footer && this.siteConfig.footer.backgroundColor ? this.siteConfig.footer.backgroundColor : this.$_themeMixin_getThemeColor('primaryDarker')
    },
    whitelabelTextColor() {
      return this.siteConfig && this.siteConfig.footer && this.siteConfig.footer.textColor ? this.siteConfig.footer.textColor : this.$_themeMixin_getThemeColor('primaryLight')
    }
  },
  methods: {
    generateShareLink(site) {
      switch (site) {
        case 'twitter':
          return 'https://twitter.com/narrative_io'
          break;
        case 'linkedin':
          return 'https://www.linkedin.com/company/narrative-io/'
          break; 
      }
    }
  }
}  
</script>

<style lang="sass" scoped>
.footer .logo
  img
    height: 24px
  h2
    font-size: 28px
.footer
  +nio-page
  border-top: 3px solid $c-primary-darker
  height: 160px
  @media (max-width: $breakpoint-md)
    height: unset
  .text-primary-light
    color: #AEB9E8
  .nio-container
    height: 100%
    display: flex
    align-items: center
    justify-content: space-between
    @media (max-width: $breakpoint-md)
      flex-direction: column
      padding: 60px 20px
      text-align: center
    .left, .right
      width: 220px
    .left, .center
      @media (max-width: $breakpoint-md)
        margin-bottom: 40px
    .center
      display: flex
      flex-direction: column
      align-items: center
      max-width: 354px
      width: 80%
      .privacy-policy
        color: white
      .logo
        margin-bottom: 10px
    .right  
      display: flex
      justify-content: space-between
      .share-link
        display: flex
        ::v-deep .nio-icon
          padding-bottom: 3px
          transform: translateY(-2px)
        &:hover
          text-decoration: none 
          .text
            text-decoration: underline
            color: $c-white
        .text
          margin-left: 5px
  &.white-label
    .nio-container
      justify-content: center
      .center
        width: 100%
</style>
