import CartMutations from '@/stores/cart/CartMutations'
import sl from '@/utils/serviceLayer'
import sum from 'lodash/sum'
import { isNarrativeDomain } from '@/utils/siteConfig'

// If is DSM 100 else 1
const minBudgetAmount = isNarrativeDomain ? 100 : 1

function makePackageOptions(minForecastedIdCount, cartIdCountTotal, averageCpmPriceInDollars) {
  const everythingPrice = cartIdCountTotal * averageCpmPriceInDollars / 1000
  let acc = {
    small: {
      price: roundPrice(everythingPrice * 0.1),
      idCount: cartIdCountTotal * 0.1
    },
    medium: {
      price: roundPrice(everythingPrice * 0.5),
      idCount: cartIdCountTotal * 0.5
    },
    everything: {
      price: roundPrice(everythingPrice),
      idCount: cartIdCountTotal
    }
  }
  if (acc.everything.price < minBudgetAmount) {
    acc.everything.price = minBudgetAmount
    acc.medium = null
    acc.small = null
  } else if (acc.medium.price < minBudgetAmount) {
    acc.medium.price = minBudgetAmount
    acc.medium.idCount = minForecastedIdCount
    acc.small = null
  } else if (acc.small.price < minBudgetAmount) {
    acc.small.price = minBudgetAmount
    acc.small.idCount = minForecastedIdCount
  }
  return acc
}

function roundPrice(price) {
  return Math.ceil(price / 10) * 10;
}

function averageCpmPriceInDollars(products) {
  return sum((products.map(p => p.idCount * p.priceInMicroCents))) / sum(products.map(p => p.idCount)) / 100000 
}

export default {
  computed: {
    $_cartMixin_cart() { return this.$store.getters.cart },
    $_cartMixin_cartTotalIdCount() { return this.$store.getters.idCount },
    $_cartMixin_cartTotalCostInDollars() { return this.$store.getters.cart.map(i => i.priceInMicroCents / 100000000 * i.idCount).reduce((a,b) => a + b) },
    $_cartMixin_cartProductCount() { return this.$store.getters.cart.length },
    $_cartMixin_cartEmpty() { return this.$store.getters.cart.length == 0 },
    $_cartMixin_averageCpmPriceInDollars() { return averageCpmPriceInDollars(this.$_cartMixin_cart) },
    $_cartMixin_dedupFactor() { this.$_cartMixin_cartTotalIdCount / sum((this.$_cartMixin_cart.map(p => p.idCount))) },
    $_cartMixin_cartPackageOptions() { 
      return makePackageOptions(this.$_cartMixin_forecastCartIdCount(minBudgetAmount), this.$_cartMixin_cartTotalIdCount, this.$_cartMixin_averageCpmPriceInDollars)
    },
    $_cartMixin_minShopBudget() { return minBudgetAmount },
    $_cartMixin_cartLimitExceeded() { return this.$_cartMixin_cartProductCount > 0 ? true : false }
  },
  methods: {
    $_cartMixin_emptyCart() { this.$store.commit(CartMutations.EMPTY_CART) },
    $_cartMixin_cartContainsProduct(productId) { return this.$store.getters.containsProduct(productId)},
    $_cartMixin_addProductToCart(product) { this.$store.dispatch(CartMutations.ADD_CART_PRODUCT, product) },
    $_cartMixin_removeProductFromCart(productId) { this.$store.dispatch(CartMutations.REMOVE_CART_PRODUCT, productId) },
    $_cartMixin_forecastCartIdCount(budgetAmount) {
      const everythingPrice = this.$_cartMixin_cartTotalIdCount * this.$_cartMixin_averageCpmPriceInDollars / 1000
      return this.$_cartMixin_cartTotalIdCount * budgetAmount / everythingPrice
    },
    $_cartMixin_forecastSubscriptionIdCount(dataStreams, subscriptionIdCountTotal, budgetAmount) {
      const everythingPrice = subscriptionIdCountTotal * averageCpmPriceInDollars(dataStreams) / 1000
      return budgetAmount < everythingPrice ?
        subscriptionIdCountTotal * budgetAmount / everythingPrice :
        subscriptionIdCountTotal
    },
    $_cartMixin_subscriptionPackageOptions(dataStreams, cartIdCountTotal) {
      return makePackageOptions(this.$_cartMixin_forecastSubscriptionIdCount(dataStreams, cartIdCountTotal, minBudgetAmount), cartIdCountTotal, averageCpmPriceInDollars(dataStreams))
    },
    $_cartMixin_getSubscriptionIdCount(dataStreams) {
      return new Promise((resolve, reject) => {
        if (dataStreams.length > 1) {
          sl.post('/api/products/combinedCardinality', {ids: dataStreams.map(p => p.id)})
            .then(resp => {
              resolve(resp.data)
            })
        }
        else {
          resolve(sum(dataStreams.map(p => p.idCount)))
        }
      })
    }
  }
}