import Vue           from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

Vue.mixin({
  methods: {
    notify(title, text) {
      this.$notify({
        group: 'main',
        title: title,
        text: text,
        type: 'success',
        duration: 5000,
      });
    }
  }
}) 