import { ref, onMounted, onUnmounted } from 'vue'
import { useThrottleFn } from '@vueuse/core'
import { localRouteKey } from "./localStorage"
import { router, route } from '../plugins'
import store from '../plugins/vuex'

export function useSessionTimeout(
    activityEvents = ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
    lastActivityTimestampKey = 'nio:dsm-last-activity-timestamp',
    // 4 hours
    timeoutMs = 4 * 60 * 60 * 1000,
    // for testing: 30 seconds
    // timeoutMs = 30 * 1000,
    throttleDelay = 10 * 1000
) {
    function goToLoginPage(returnTo) {
        if (returnTo) {
            localStorage.setItem(localRouteKey, returnTo)
        }
        router.push('/login')
    }

    const logoutTimer = ref(undefined)

    function logoutUser() {
        const loggedIn = store.getters.authenticated
        if (loggedIn) {
            console.log("Logging out user....")
            store.dispatch('clear')
        }

        console.log("Redirecting to login page....")
        //goToLoginPage(route.fullPath)
        goToLoginPage()
    }

    function maybeLogoutUser() {
        if (isSessionExpired()) {
            logoutUser()
        }
    }

    function maybeLogoutUserAndResetTimer() {
        resetTimer()
        maybeLogoutUser()
    }


    function setTimer() {
        // We might have multiple tabs open (sharing the same local storage), so we cannot timeout exactly using timeoutMs 
        // and check every the last activity timestamp every instead. 
        logoutTimer.value = setTimeout(maybeLogoutUserAndResetTimer, Math.min(timeoutMs, 60 * 1000))
    }

    function clearTimer() {
        clearTimeout(logoutTimer.value)
    }

    function resetTimer() {
        clearTimer()
        setTimer()
    }

    function saveLastActivityTimestamp() {
        localStorage.setItem(lastActivityTimestampKey, Date.now().toString())
    }

    function getLastActivityTimestamp() {
        const lastActivityTimestampStr = localStorage.getItem(lastActivityTimestampKey)
        const parseResult = lastActivityTimestampStr !== null ? parseInt(lastActivityTimestampStr) : null
        return parseResult != null ? (isNaN(parseResult) ? null : parseResult) : null
    }

    function isSessionExpired() {
        const lastActivityTimestamp = getLastActivityTimestamp() ?? Date.now()
        const delta = Date.now() - lastActivityTimestamp
        return delta > timeoutMs
    }

    const throttledSaveLastActivityTimestamp = useThrottleFn(saveLastActivityTimestamp, throttleDelay)

    onMounted(() => {
        maybeLogoutUser()
        setTimer()

        activityEvents.forEach(function (event) {
            window.addEventListener(event, throttledSaveLastActivityTimestamp)
        })
    })

    onUnmounted(() => {
        activityEvents.forEach(function (event) {
            window.removeEventListener(event, throttledSaveLastActivityTimestamp)
        })
        clearTimer()
    })

    return { logoutTimer }
}
