import axios from 'axios'
import store from '@/plugins/vuex/index'
import Mutations from '@/stores/user/UserMutations'

// Need to add in context company when needed.
class Service {
  constructor() {
    let service = axios.create()

    service.interceptors.request.use((config) => {
      // No need to pass the Authorization header anymore, openapi-auth and dsm-auth cookies are passed 
      // automatically by the browser

      if (store.getters.contextualCompanyId) {
        config.headers.context = store.getters.contextualCompanyId
      }
      return config
    })

    service.interceptors.response.use((response) => response, (errorResponse) => {
      if (errorResponse.response.status == 403) {
        // 403 from server means our auth token is expired.  Force login
        store.commit(Mutations.LOGOUT)
      }
      return Promise.reject(errorResponse)
    })

    this.service = service
  }

  get(path) {
    return this.service.get(path).then(response => response).catch(err => Promise.reject(err?.response))
  }

  post(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      data: payload
    }).then(response => response)
      .catch(err => Promise.reject(err?.response))
  }

  put(path, payload) {
    return this.service.request({
      method: 'PUT',
      url: path,
      data: payload
    }).then(response => response)
      .catch(err => Promise.reject(err?.response))
  }

  patch(path) {
    return this.service.request({
      method: 'PATCH',
      url: path,
    }).then(response => response)
      .catch(err => Promise.reject(err?.response))
  }

  delete(path, payload) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      data: payload
    }).then(response => response)
      .catch(err => Promise.reject(err?.response))
  }
}

export default new Service;