import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { clearCustomAppParameters } from '@/utils/localStorage'

let router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'app-runner' && to.name !== 'app-runner-first-access') {
    clearCustomAppParameters()
  }
  next()
})

router.afterEach((to, from) => {
  let _hsq = window._hsq = window._hsq || []
  _hsq.push(['setPath', to.path])
  _hsq.push(['trackPageView'])
})

Vue.use(VueRouter)

export default router
