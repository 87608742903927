import Vue from 'vue'
import Vuex from 'vuex'
import userStore from '@/stores/user/UserStore'
import cartStore from '@/stores/cart/CartStore'
import globalStore from '@/stores/global/GlobalStore'
import openApiStore from '@/stores/openApiStore'
import UserMixin from '@/shared-components/UserMixin'
import WhitelabelStore from '@/stores/whitelabel/whitelabelStore'
import UserMutations from '@/stores/user/UserMutations';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user: userStore,
    cart: cartStore,
    global: globalStore,
    openapi: openApiStore,
    whitelabel: WhitelabelStore,
  },
  strict: process.env.NODE_ENV !== 'production'
})
export default store

Vue.mixin(UserMixin)