
import GlobalMutations from './GlobalMutations';
export default {
  state: {
   showFooter: true
  },
  mutations: {
    [GlobalMutations.SHOW_FOOTER](state, value) {
      state.showFooter = value
    }
  },
  actions: {
    [GlobalMutations.SHOW_FOOTER] ({ commit, dispatch, state }) {
      commit(GlobalMutations.SHOW_FOOTER)
    },
  },
  getters: {
    showFooter: state => state.showFooter,
  },
}