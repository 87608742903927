import Vue from 'vue'

Vue.filter('currency', function (value) {
	const convertedVal = parseFloat(value.toFixed(2)).toLocaleString()
	if (convertedVal.indexOf('.') > - 1) {
		if (convertedVal.split('.')[1].length === 1) {
				return '$' + convertedVal + '0'
		} else {
			return '$' + convertedVal
		}
	}
	return '$' + convertedVal + '.00'
});