import { toKebabCase } from '@/utils/theme'
import { mapGetters } from 'vuex'
import siteConfig from '@/utils/siteConfig'

export default {
  methods: {
    $_themeMixin_getThemeColor(colorName) {
      let styles = document.documentElement.style
      return styles.getPropertyValue(`--nio-theme-${toKebabCase(colorName)}`)
    }
  },
  data() {
    return {
      $_themeMixin_metaShopName: null
    }
  },
  computed: {		
    ...mapGetters(['siteConfig']),
    $_themeMixin_siteConfigHero() {
      return this.siteConfig && this.siteConfig.pages && this.siteConfig.pages.home ? this.siteConfig.pages.home.hero : undefined
    },
    $_themeMixin_isNarrativeDomain() {
      return siteConfig.narrativeHostnames.includes(window.location.hostname)
    }
  },
  mounted() {
    siteConfig.getSiteConfig().then(config => {
      this.$_themeMixin_metaShopName = config.config ? config.config.shopName : null
    })
  }
}